import React from "react";

const MainPage = () => {

    return (
        <div className="container text-center">
            <p>
                <br/>
                <h4>ยินดีต้อนรับสู่ แอปพลิเคชัน LEXMEN</h4>
                <br/>
                เลือกใช้บริการตามเมนูด้านบนได้เลย 🦖 🐥
            </p>
        </div>
    );
};

export default MainPage;
