import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Auth0Provider} from "./react-auth0-spa";
import config from "./auth_config.json";
import App from "./App";

ReactDOM.render(
    <BrowserRouter>
        <Auth0Provider
            domain={config.domain}
            client_id={config.clientId}
            redirect_uri={window.location.origin}>
            <App/>
        </Auth0Provider>
    </BrowserRouter>,
    document.getElementById("root")
)
;
