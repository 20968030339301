import React, {useState} from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import moment from 'moment';
import 'moment/locale/th';
import InApp from 'detect-inapp';
import ReactGA from 'react-ga';

const WillForm = () => {
    const API_ENDPOINT = "https://api.lexmenth.com";
    // const API_ENDPOINT = "http://localhost:3000";

    moment().locale('th');

    const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);

    const MAX_HEIRS = 10;

    const initialHeirs = [];
    for (let i = 0; i < MAX_HEIRS; i++) {
        initialHeirs.push({
            prefix: "นาย",
            firstName: "",
            lastName: "",
        });
    }

    const initialWillFormState = {
        willSigningAddress: "",
        testatorPrefix: "นาย",
        testatorFirstName: "",
        testatorLastName: "",
        testatorAddress: "",
        testatorAge: undefined,
        noOfHeirs: 1,
        executorPrefix: "นาย",
        executorFirstName: "",
        executorLastName: "",
        witness1Prefix: "นาย",
        witness1FirstName: "",
        witness1LastName: "",
        witness2Prefix: "นาย",
        witness2FirstName: "",
        witness2LastName: "",
        willKeepingAddress: "",
        copyWillSigningAddressFromTestatorAddress: false,
        copyWillKeepingAddressFromTestatorAddress: false
    };

    const [willData, setWillData] = useState(initialWillFormState);
    const [heirsData, setHeirsData] = useState(initialHeirs);
    const [email, setEmail] = useState("");
    const [previewing, setPreviewing] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [emailing, setEmailing] = useState(false);
    const [previewWillData, setPreviewWillData] = useState({});

    const handleSubmit = event => {
        ReactGA.event({
            category: "WillForm",
            action: "User clicked Preview button",
        });
        setPreviewWillData({
            willSigningAddress: willData.willSigningAddress.replace(/[\r\n]+/g, " ").trim(),
            willSigningDate: moment().add(543, 'year').format('LL'),
            testatorName: `${willData.testatorPrefix.trim()}${willData.testatorFirstName.trim()} ${willData.testatorLastName.trim()}`,
            testatorAge: willData.testatorAge,
            testatorAddress: willData.testatorAddress.replace(/[\r\n]+/g, " ").trim(),
            executorName: `${willData.executorPrefix.trim()}${willData.executorFirstName.trim()} ${willData.executorLastName.trim()}`,
            heirsListOfNames: willData.noOfHeirs === 1
                ? `${heirsData[0].prefix.trim()}${heirsData[0].firstName.trim()} ${heirsData[0].lastName.trim()}`
                :heirsData.slice(0, willData.noOfHeirs).map((value, index) => {
                return `${index + 1}.) ${value.prefix.trim()}${value.firstName.trim()} ${value.lastName.trim()}`
            }).join(" และ "),
            willKeepingAddress: willData.willKeepingAddress,
            witness1Name: `${willData.witness1Prefix.trim()}${willData.witness1FirstName.trim()} ${willData.witness1LastName.trim()}`,
            witness2Name: `${willData.witness2Prefix.trim()}${willData.witness2FirstName.trim()} ${willData.witness2LastName.trim()}`,
        });
        setPreviewing(true);
        event.preventDefault();
    }

    const handleDownload = () => {
        ReactGA.event({
            category: "WillForm",
            action: "User clicked Download button",
        });
        setDownloading(true);
        axios({
            url: `${API_ENDPOINT}/tools/will/docx`,
            method: 'POST',
            data: {...willData, heirs: heirsData.slice(0, willData.noOfHeirs)},
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'will.docx');
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            alert("เกิดข้อผิดพลาด! สร้างพินัยกรรมไม่ได้ ขอโทษด้วยจ้า คราวหน้าลองใหม่นะ")
        }).finally(() => setDownloading(false));
    }

    const handleSendEmail = event => {
        ReactGA.event({
            category: "WillForm",
            action: "User clicked Email button",
        });
        setEmailing(true);
        axios({
            url: `${API_ENDPOINT}/tools/will/email`,
            method: 'POST',
            data: {...willData, heirs: heirsData.slice(0, willData.noOfHeirs), emailTo: email}
        }).then((response) => {
            alert("ส่งพินัยกรรมไปที่อีเมล " + email + " แล้ว! อีกสักครู่เข้าไปเช็คอีเมลได้เลยจ้า");
        }).catch(() => {
            alert("เกิดข้อผิดพลาด! ส่งพินัยกรรมเข้าอีเมลไม่ได้ ขอโทษด้วยจ้า คราวหน้าลองใหม่นะ");
        }).finally(() => setEmailing(false));
        event.preventDefault();
    }

    const handleInputChange = event => {
        const {name, value} = event.target;
        setWillData({...willData, [name]: value});
    };

    const handleHeirInputChange = event => {
        const {name, value} = event.target;
        const heirIndex = event.target.getAttribute("heirindex");
        const newHeirsData = [...heirsData];
        newHeirsData[heirIndex][name] = value;
        setHeirsData(newHeirsData);
    };

    const handleTestatorAddress = event => {
        const {value} = event.target;
        const willSigningAddress = willData.copyWillSigningAddressFromTestatorAddress ? value : willData.willSigningAddress;
        const willKeepingAddress = willData.copyWillKeepingAddressFromTestatorAddress ? value : willData.willKeepingAddress;
        setWillData({
            ...willData,
            testatorAddress: value,
            willSigningAddress: willSigningAddress,
            willKeepingAddress: willKeepingAddress
        });
    };

    const handleCopyWillSigningAddressFromTestatorAddress = event => {
        const {checked} = event.target;
        const address = checked ? willData.testatorAddress : "";
        setWillData({...willData, willSigningAddress: address, copyWillSigningAddressFromTestatorAddress: checked});
    };

    const handleCopyWillKeepingAddressFromTestatorAddress = event => {
        const {checked} = event.target;
        const address = checked ? willData.testatorAddress : "";
        setWillData({...willData, willKeepingAddress: address, copyWillKeepingAddressFromTestatorAddress: checked});
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <h4>บริการช่วยสร้างพินัยกรรม (ฟรี)</h4>
                <small>
                    กรุณากรอกข้อมูลด้านล่าง เพื่อให้ระบบช่วยสร้างพินัยกรรมให้ท่าน
                    และ เพื่อความเป็นส่วนตัว เราจะไม่บันทึกข้อมูลส่วนใดๆ ไว้ในระบบของเรา
                </small>
                <br/>
                <br/>
                <h5>ผู้ทำพินัยกรรม</h5>
                <br/>
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="testatorPrefix">คำนำหน้า</label>
                            <select className="form-control" id="testatorPrefix" name="testatorPrefix"
                                    value={willData.testatorPrefix}
                                    onChange={handleInputChange}>
                                <option>นาย</option>
                                <option>นาง</option>
                                <option>นางสาว</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="testatorFirstName">ชื่อ</label>
                            <input required name="testatorFirstName" type="text" className="form-control"
                                   id="testatorFirstName"
                                   aria-describedby="testatorFirstName"
                                   value={willData.testatorFirstName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="testatorLastName">นามสกุล</label>
                            <input required name="testatorLastName" type="text" className="form-control"
                                   id="testatorLastName"
                                   aria-describedby="testatorLastName"
                                   value={willData.testatorLastName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col col-lg-2 col-4">
                        <div className="form-group">
                            <label htmlFor="testatorAge">อายุ</label>
                            <div className="input-group mb-3">
                                <input type="number"
                                       required
                                       id="testatorAge" name="testatorAge"
                                       className="form-control"
                                       aria-label="testatorAge"
                                       aria-describedby="testatorAge"
                                       value={willData.testatorAge}
                                       onChange={handleInputChange}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">ปี</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col col-lg-6">
                        <div className="form-group">
                            <label htmlFor="testatorAddress">ที่อยู่ตามบัตรประชาชน</label>
                            <textarea required name="testatorAddress" className="form-control" id="testatorAddress"
                                      rows="2"
                                      value={willData.testatorAddress}
                                      onChange={handleTestatorAddress}/>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col col-lg-6">
                        <div className="form-group">
                            <label htmlFor="willSigningAddress">สถานที่ทำพินัยกรรม</label>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       id="copyWillSigningAddressFromTestatorAddress"
                                       value={willData.copyWillSigningAddressFromTestatorAddress}
                                       onChange={handleCopyWillSigningAddressFromTestatorAddress}/>
                                <label className="form-check-label small"
                                       htmlFor="copyWillSigningAddressFromTestatorAddress">
                                    เหมือนที่อยู่ตามบัตรประชาชน
                                </label>
                            </div>
                            {!willData.copyWillSigningAddressFromTestatorAddress &&
                            <textarea required name="willSigningAddress" className="form-control"
                                      id="willSigningAddress"
                                      rows="2"
                                      value={willData.willSigningAddress}
                                      onChange={handleInputChange}/>
                            }
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col col-lg-6">
                        <div className="form-group">
                            <label htmlFor="willKeepingAddress">สถานที่เก็บพินัยกรรม</label>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       id="copyWillKeepingAddressFromTestatorAddress"
                                       value={willData.copyWillKeepingAddressFromTestatorAddress}
                                       onChange={handleCopyWillKeepingAddressFromTestatorAddress}/>
                                <label className="form-check-label small"
                                       htmlFor="copyWillKeepingAddressFromTestatorAddress">
                                    เหมือนที่อยู่ตามบัตรประชาชน
                                </label>
                            </div>
                            {!willData.copyWillKeepingAddressFromTestatorAddress &&
                            <textarea required name="willKeepingAddress" className="form-control"
                                      id="willKeepingAddress"
                                      rows="2"
                                      value={willData.willKeepingAddress}
                                      onChange={handleInputChange}/>
                            }
                        </div>
                    </div>
                </div>
                <hr/>
                <h5>ผู้รับมรดก</h5>
                <br/>
                <div className="form-row">
                    <div className="col col-lg-2 col-4">
                        <div className="form-group">
                            <label htmlFor="noOfHeirs">จำนวน</label>
                            <div className="input-group mb-3">
                                <select className="form-control" id="noOfHeirs" name="noOfHeirs"
                                        value={willData.noOfHeirs}
                                        onChange={handleInputChange}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text">คน</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {heirsData.slice(0, willData.noOfHeirs).map((value, index) => {
                    return <div className="form-row" key={"heir" + index}>
                        <div className="col col-lg-2">
                            <div className="form-group">
                                <label htmlFor="heirPrefix">คำนำหน้า</label>
                                <select className="form-control"
                                        id={"heirPrefix" + index}
                                        name="prefix"
                                        heirindex={index}
                                        value={value.prefix}
                                        onChange={handleHeirInputChange}>
                                    <option>นาย</option>
                                    <option>นาง</option>
                                    <option>นางสาว</option>
                                </select>
                            </div>
                        </div>
                        <div className="col col-lg-2">
                            <div className="form-group">
                                <label htmlFor="heirFirstName">ชื่อ</label>
                                <input required
                                       type="text"
                                       className="form-control"
                                       id={"heirFirstName" + index}
                                       name="firstName"
                                       value={value.firstName}
                                       heirindex={index}
                                       onChange={handleHeirInputChange}
                                />
                            </div>
                        </div>
                        <div className="col col-lg-2">
                            <div className="form-group">
                                <label htmlFor="heirLastName">นามสกุล</label>
                                <input required type="text" className="form-control"
                                       id={"heirLastName" + index}
                                       name="lastName"
                                       value={value.lastName}
                                       heirindex={index}
                                       onChange={handleHeirInputChange}
                                />
                            </div>
                        </div>
                    </div>
                })}
                <hr/>
                <h5>ผู้จัดการมรดก</h5>
                <br/>
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="executorPrefix">คำนำหน้า</label>
                            <select className="form-control" id="executorPrefix" name="executorPrefix"
                                    value={willData.executorPrefix}
                                    onChange={handleInputChange}>
                                <option>นาย</option>
                                <option>นาง</option>
                                <option>นางสาว</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="executorFirstName">ชื่อ</label>
                            <input required name="executorFirstName" type="text" className="form-control"
                                   id="executorFirstName"
                                   aria-describedby="executorFirstName"
                                   value={willData.executorFirstName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="executorLastName">นามสกุล</label>
                            <input required name="executorLastName" type="text" className="form-control"
                                   id="executorLastName"
                                   aria-describedby="executorLastName"
                                   value={willData.executorLastName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <hr/>
                <h5>พยาน (คนที่ 1)</h5>
                <span className="badge badge-warning">ห้ามเป็นคนเดียวกับผู้รับมรดก</span>
                <br/>
                <br/>
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="witness1Prefix">คำนำหน้า</label>
                            <select className="form-control" id="witness1Prefix" name="witness1Prefix"
                                    value={willData.witness1Prefix}
                                    onChange={handleInputChange}>
                                <option>นาย</option>
                                <option>นาง</option>
                                <option>นางสาว</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="witness1FirstName">ชื่อ</label>
                            <input required name="witness1FirstName" type="text" className="form-control"
                                   id="witness1FirstName"
                                   aria-describedby="witness1FirstName"
                                   value={willData.witness1FirstName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="witness1LastName">นามสกุล</label>
                            <input required name="witness1LastName" type="text" className="form-control"
                                   id="witness1LastName"
                                   aria-describedby="witness1LastName"
                                   value={willData.witness1LastName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <hr/>
                <h5>พยาน (คนที่ 2)</h5>
                <span className="badge badge-warning">ห้ามเป็นคนเดียวกับผู้รับมรดก</span>
                <br/>
                <br/>
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="witness2Prefix">คำนำหน้า</label>
                            <select className="form-control" id="witness2Prefix" name="witness2Prefix"
                                    value={willData.witness2Prefix}
                                    onChange={handleInputChange}>
                                <option>นาย</option>
                                <option>นาง</option>
                                <option>นางสาว</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="witness2FirstName">ชื่อ</label>
                            <input required name="witness2FirstName" type="text" className="form-control"
                                   id="witness2FirstName"
                                   aria-describedby="witness2FirstName"
                                   value={willData.witness2FirstName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="witness2LastName">นามสกุล</label>
                            <input required name="witness2LastName" type="text" className="form-control"
                                   id="witness2LastName"
                                   aria-describedby="witness2LastName"
                                   value={willData.witness2LastName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <hr/>
                <Button type="submit" variant="success">
                    🔎 คลิกเพื่อดูพินัยกรรม
                </Button>
                <br/>
                <br/>
            </form>
            <Modal show={previewing} onHide={() => setPreviewing(false)} scrollable className="small">
                <Modal.Header closeButton>
                    <Modal.Title><small>ตัวอย่างข้อความพินัยกรรม</small></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">พินัยกรรม</p>
                    <p className="text-right">ทำที่ {previewWillData.willSigningAddress}</p>
                    <p className="text-right">วันที่ {previewWillData.willSigningDate}</p>
                    <p>
                        &nbsp;&nbsp;พินัยกรรมฉบับนี้
                        ข้าพเจ้า {previewWillData.testatorName} อายุ {previewWillData.testatorAge} ปี
                        อยู่บ้านเลขที่ {previewWillData.testatorAddress}
                        ทำขึ้นเพื่อเป็นคำสั่งสุดท้ายของข้าพเจ้าเมื่อข้าพเจ้าวายชนม์ให้จัดการทรัพย์สินของข้าพเจ้าดังต่อไปนี้
                    </p>
                    <p>
                        ข้อ 1. พินัยกรรมฉบับนี้ เป็นพินัยกรรมฉบับสุดท้ายของข้าพเจ้า
                        และข้าพเจ้าขอยกเลิกพินัยกรรมและ/หรือคำสั่งอื่นใดอันเกี่ยวกับทรัพย์สินของข้าพเจ้า
                        ซึ่งอาจเคยทำหรือมีไว้ก่อนหน้าวันทำพินัยกรรมฉบับนี้เสียทั้งสิ้น
                    </p>
                    <p>
                        ข้อ 2. ข้าพเจ้าขอแต่งตั้งให้ {previewWillData.executorName}เป็นผู้จัดการมรดกของข้าพเจ้า
                        เพื่อดำเนินการจัดแบ่งปันทรัพย์มรดกของข้าพเจ้าให้เป็นไปตามพินัยกรรมฉบับนี้
                    </p>
                    <p>
                        ข้อ 3. ทรัพย์สินทั้งหมดที่ข้าพเจ้าถือกรรมสิทธิ์
                        หรือที่ข้าพเจ้ามีชื่อแต่งเพียงผู้เดียวหรือถือครองร่วมกับผู้อื่น
                        ข้าพเจ้าขอยกให้แก่ {previewWillData.heirsListOfNames}
                    </p>
                    <p>
                        &nbsp;&nbsp;พินัยกรรมฉบับนี้ทำขึ้นเป็นสามฉบับ มีข้อความถูกต้องตรงกัน
                        โดยข้าพเจ้าได้เก็บรักษาไว้ฉบับหนึ่ง
                        ไว้ที่ {previewWillData.willKeepingAddress}
                    </p>
                    <p>
                        &nbsp;&nbsp;ขณะทำพินัยกรรมฉบับนี้ ข้าพเจ้ามีสติสัมปชัญญะสมบูรณ์ทุกประการ
                        และข้าพเจ้าได้อ่านและเข้าใจข้อความในพินัยกรรมนี้แล้ว
                        เห็นว่ามีข้อความถูกต้องตรงตามความประสงค์ของข้าพเจ้า
                        จึงได้ลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยานข้างท้ายนี้
                    </p>
                    <p className="text-right">
                        _______________________________ผู้ทำพินัยกรรม<br/>
                        {previewWillData.testatorName}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <p>
                        &nbsp;&nbsp;
                        ข้าพเจ้าผู้ลงนามข้างท้ายนี้ ขอรับรองว่า {previewWillData.testatorName}
                        ได้ทำพินัยกรรมต่อหน้าข้าพเจ้า
                        และขอรับรองว่าขณะทำพินัยกรรม {previewWillData.testatorName}
                        ผู้ทำพินัยกรรมมีสติสัมปชัญญะสมบูรณ์ทุกประการ
                        และได้ลงลายมือชื่อข้างบนนี้ต่อหน้าข้าพเจ้าจริง
                        ข้าพเจ้าจึงได้ลงลายมือชื่อเป็นพยานไว้ในพินัยกรรมฉบับนี้
                    </p>
                    <p className="text-right">
                        ______________________________________พยาน<br/>
                        ( {previewWillData.witness1Name} )
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <p className="text-right">
                        ______________________________________พยาน<br/>
                        ( {previewWillData.witness2Name} )
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {!inapp.isInApp
                        ? <Button type="button" variant="primary" className="btn-sm" onClick={handleDownload}
                                  disabled={downloading}>
                            {downloading
                                ? "กำลังสร้างไฟล์.."
                                : "ดาวน์โหลดไฟล์พินัยกรรม (docx)"
                            }
                        </Button>
                        : <form onSubmit={handleSendEmail}>
                            <div className="input-group input-group-sm">
                                <input type="email" className="form-control input-sm" placeholder="อีเมลที่ใช้รับไฟล์"
                                       aria-label="Email" aria-describedby="basic-addon2"
                                       value={email}
                                       onChange={event => setEmail(event.target.value)}
                                       required/>
                                <div className="input-group-append">
                                    <button className="btn btn-success"
                                            type="submit"
                                            disabled={emailing}>
                                        {emailing
                                            ? "กำลังส่งพินัยกรรม เข้าอีเมล.."
                                            : "✉️ ส่งพินัยกรรม (docx) เข้าอีเมล"
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    }
                    <Button variant="info" className="btn-sm" onClick={() => setPreviewing(false)}>
                        กลับไปหน้ากรอกข้อมูล
                    </Button>
                    <br/>
                    <br/>
                    <small>*ข้อมูลที่ลูกค้ากรอกหรือเปิดเผยในแอปพลิเคชั่น LEXMEN จะถูกนำไปจัดทำลงเอกสารแล้วส่งให้ลูกค้า หลังจากที่ได้ส่งเอกสารให้ลูกค้าแล้ว
                        บริษัทจะลบข้อมูลและเอกสารออกจากระบบคอมพิวเตอร์ของบริษัท โดยไม่มีการทำสำเนาหรือเก็บข้อมูลหรือเอกสารดังกล่าวไว้แต่อย่างใด</small>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default WillForm;
