import React from "react";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import {useAuth0} from "./react-auth0-spa";
import 'bootstrap/dist/css/bootstrap.min.css';

import RepaymentForm from "./components/services/RepaymentForm";
import MainPage from "./components/MainPage";
import ToolsPage from "./components/ToolsPage";
import ServicesPage from "./components/ServicesPage";
import WillForm from "./components/tools/WillForm";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import {LinkContainer} from 'react-router-bootstrap'
import ReactGA from 'react-ga';
import {useHistory} from "react-router-dom";

function App() {
    const {loading} = useAuth0();
    const history = useHistory();

    const trackingId = "UA-171678257-1";
    ReactGA.initialize(trackingId);
    history.listen(location => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });

    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand as={Link} to="/">LEXMEN</Navbar.Brand>
                <Nav className="mr-auto">
                    <NavDropdown title="บริการฟรี" id="toolsDropdown">
                        <LinkContainer to="/tools/will">
                            <NavDropdown.Item>สร้างพินัยกรรม (แบบธรรมดา)</NavDropdown.Item>
                        </LinkContainer>
                    </NavDropdown>
                    <NavDropdown title="บริการพรีเมียม" id="servicesDropdown">
                        <LinkContainer to="/services/repayment">
                            <NavDropdown.Item>ขอรับชำระหนี้</NavDropdown.Item>
                        </LinkContainer>
                    </NavDropdown>
                </Nav>
            </Navbar>
            <div className="container mt-3">
                {loading
                    ? <div>กำลังโหลด รอแป้บนะ..</div>
                    : <Switch>
                        <Route exact path={["/"]} component={MainPage}/>

                        <Route exact path={["/tools/will"]} component={WillForm}/>

                        {/*<PrivateRoute useAuth0={useAuth0} exact path={["/services/repayment"]}*/}
                        {/*              component={RepaymentForm}/>*/}

                        <Route exact path={["/services/repayment"]} component={RepaymentForm}/>

                        <Redirect from='*' to='/'/>
                    </Switch>
                }
            </div>
        </>
    );
}

export default App;
