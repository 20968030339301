import React, {useState} from "react";
import ReactGA from "react-ga";
import axios from "axios";
import InApp from "detect-inapp";
import Button from "react-bootstrap/Button";

const RepaymentForm = () => {

    const API_ENDPOINT = "https://api.lexmenth.com";
    // const API_ENDPOINT = "http://localhost:3000";

    const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);

    const initialRepaymentFormState = {
        caseNumber: "",
        selectedDebtor: "TG",
        debtorPrefix: "บริษัท",
        debtorName: "",
        creditorPrefix: "นาย",
        creditorFirstName: "",
        creditorLastName: "",
        creditorJob: "",
        creditorAddress: "",
        creditorDOB: undefined,
        useLexmenAttorney: false,
        contactNumber : "",
        attorneyPrefix: "นาย",
        attorneyFirstName: "",
        attorneyLastName: "",
        attorneyJob: "ทนายความ",
        attorneyAddress: "",
        attorneyDOB: undefined,
        rehabDate: undefined,
        selectedDebtType: undefined,
        otherDebtType: "",
        debtName: "",
        debtValue: undefined,
        debtInterestRate: undefined,
        debtDefaultInterestRate: undefined,
        debtDefaultDate: undefined,
        debtRedeemDate: undefined,
        hasDebtGuarantee: false,
        debtGuarantee: ""
    };
    const [repaymentData, setRepaymentData] = useState(initialRepaymentFormState);
    const [downloading, setDownloading] = useState(false);
    const [emailing, setEmailing] = useState(false);
    const [email, setEmail] = useState("");

    const handleDownload = event => {
        ReactGA.event({
            category: "RepaymentForm",
            action: "User clicked Download button",
        });
        setDownloading(true);
        axios({
            url: `${API_ENDPOINT}/services/repayment/docx`,
            method: 'POST',
            data: repaymentData,
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'repayment.docx');
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            alert("เกิดข้อผิดพลาด! สร้างใบคำขอรับชำระหนี้ไม่ได้ ขอโทษด้วยจ้า คราวหน้าลองใหม่นะ")
        }).finally(() => setDownloading(false));
    }

    const handleSendEmail = event => {
        ReactGA.event({
            category: "RepaymentForm",
            action: "User clicked Email button",
        });
        setEmailing(true);
        axios({
            url: `${API_ENDPOINT}/services/repayment/email`,
            method: 'POST',
            data: {...repaymentData, emailTo: email}
        }).then((response) => {
            alert("ส่งใบคำขอรับชำระหนี้ไปที่อีเมล " + email + " แล้ว! อีกสักครู่เข้าไปเช็คอีเมลได้เลยจ้า");
        }).catch(() => {
            alert("เกิดข้อผิดพลาด! ส่งใบคำขอรับชำระหนี้เข้าอีเมลไม่ได้ ขอโทษด้วยจ้า คราวหน้าลองใหม่นะ");
        }).finally(() => setEmailing(false));
    }

    const handleSubmit = event => {
        if (inapp.isInApp) {
            handleSendEmail(event);
        } else {
            handleDownload(event);
        }
        event.preventDefault();
    }
    const handleInputChange = event => {
        const target = event.target;
        const value = (target.name === 'hasDebtGuarantee' || target.name === 'useLexmenAttorney') ? target.checked : target.value;
        const name = target.name;
        setRepaymentData({...repaymentData, [name]: value});
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <h4>ข้อมูลลูกหนี้</h4>
                <br/>
                <div className="form-row">
                    <legend className="col-form-label col-sm-2 pt-0">ลูกหนี้ของคุณคือ</legend>
                    <div className="col">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="selectedDebtor" id="selectedDebtorTG"
                                   value="TG"
                                   checked={repaymentData.selectedDebtor === "TG"}
                                   onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="selectedDebtorTG">การบินไทย</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="selectedDebtor"
                                   id="selectedDebtorOther"
                                   value="Other"
                                   checked={repaymentData.selectedDebtor === "Other"}
                                   onChange={handleInputChange}/>
                            <label className="form-check-label" htmlFor="selectedDebtorOther">ลูกหนี้รายอื่น</label>
                        </div>
                    </div>
                </div>
                <br/>
                {repaymentData.selectedDebtor === "Other" &&
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="debtorPrefix">คำนำหน้าลูกหนี้</label>
                            <select className="form-control" id="debtorPrefix" name="debtorPrefix"
                                    value={repaymentData.debtorPrefix}
                                    onChange={handleInputChange}>
                                <option>บริษัท</option>
                                <option>นาย</option>
                                <option>นาง</option>
                                <option>นางสาว</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-lg-4">
                        <div className="form-group">
                            <label htmlFor="debtorName">ชื่อลูกหนี้</label>
                            <input required name="debtorName" type="text" className="form-control" id="debtorName"
                                   aria-describedby="debtorName"
                                   placeholder="เช่น เล็กซ์เม็น จำกัด"
                                   value={repaymentData.debtorName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                }
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="rehabDate">วันที่ศาลสั่งฟื้นฟูกิจการ</label>
                            <input id="rehabDate"
                                   type="date"
                                   required
                                   name="rehabDate"
                                   className="form-control"
                                   aria-describedby="rehabDate"
                                   value={repaymentData.rehabDate}
                                   onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="caseNumber">หมายเลขคดี</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">ฟ.</span>
                                </div>
                                <input type="text"
                                       required
                                       id="caseNumber" name="caseNumber"
                                       className="form-control"
                                       aria-label="caseNumber"
                                       aria-describedby="caseNumber"
                                       value={repaymentData.caseNumber}
                                       onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <h4>ข้อมูลเจ้าหนี้ <small>(ข้อมูลของคุณ)</small></h4>
                <br/>
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="creditorPrefix">คำนำหน้า</label>
                            <select className="form-control" id="creditorPrefix" name="creditorPrefix"
                                    value={repaymentData.creditorPrefix}
                                    onChange={handleInputChange}>
                                <option>นาย</option>
                                <option>นาง</option>
                                <option>นางสาว</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="creditorFirstName">ชื่อ</label>
                            <input required name="creditorFirstName" type="text" className="form-control"
                                   id="creditorFirstName"
                                   aria-describedby="creditorFirstName"
                                   value={repaymentData.creditorFirstName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="creditorLastName">นามสกุล</label>
                            <input required name="creditorLastName" type="text" className="form-control"
                                   id="creditorLastName"
                                   aria-describedby="creditorLastName"
                                   value={repaymentData.creditorLastName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="creditorDOB">วันเกิด</label>
                            <input id="creditorDOB"
                                   required
                                   type="date"
                                   name="creditorDOB"
                                   className="form-control"
                                   aria-describedby="creditorDOB"
                                   value={repaymentData.creditorDOB}
                                   onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="col col-lg-4">
                        <div className="form-group">
                            <label htmlFor="creditorJob">อาชีพ</label>
                            <input required name="creditorJob" type="text" className="form-control"
                                   id="creditorJob"
                                   aria-describedby="creditorJob"
                                   value={repaymentData.creditorJob}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col col-lg-6">
                        <div className="form-group">
                            <label htmlFor="creditorAddress">ที่อยู่</label>
                            <textarea required name="creditorAddress" className="form-control" id="creditorAddress"
                                      rows="3"
                                      value={repaymentData.creditorAddress}
                                      onChange={handleInputChange}
                                      placeholder="เช่น 11/12 ซอยสง่ามวงศ์ แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพมหานคร 10120"/>
                        </div>
                    </div>
                </div>
                <hr/>
                <h4>ข้อมูลหนี้</h4>
                <br/>
                <div className="form-row">
                    <div className="col col-lg-4">
                        <div className="form-group">
                            <label htmlFor="selectedDebtType">ประเภทหนี้</label>
                            <select className="form-control" id="selectedDebtType" name="selectedDebtType"
                                    value={repaymentData.selectedDebtType}
                                    onChange={handleInputChange}>
                                {repaymentData.selectedDebtor === "TG" &&
                                <option selected={!repaymentData.selectedDebtType}>ค่าตั๋วโดยสาร</option>
                                }
                                <option>หุ้นกู้</option>
                                <option>อื่นๆ</option>
                            </select>
                        </div>
                    </div>
                </div>
                {repaymentData.selectedDebtType === "อื่นๆ" &&
                <div className="form-row">
                    <div className="col col-lg-4">
                        <div className="form-group">
                            <label htmlFor="otherDebtType">โปรดระบุประเภทหนี้</label>
                            <input required type="text"
                                   id="otherDebtType" name="otherDebtType"
                                   className="form-control"
                                   aria-describedby="otherDebtType"
                                   value={repaymentData.otherDebtType}
                                   onChange={handleInputChange}/>
                        </div>
                    </div>
                </div>
                }
                <div className="form-row">
                    <div className="col col-lg-4">
                        <div className="form-group">
                            <label
                                htmlFor="debtValue">มูลค่า</label>
                            <div className="input-group mb-3">
                                <input type="number"
                                       step="any"
                                       required
                                       id="debtValue" name="debtValue"
                                       className="form-control"
                                       aria-label="debtValue"
                                       aria-describedby="debtValue"
                                       value={repaymentData.debtValue}
                                       onChange={handleInputChange}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">บาท</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(repaymentData.selectedDebtType === "หุ้นกู้" || (repaymentData.selectedDebtor !== "TG" && !repaymentData.selectedDebtType)) &&
                <div className="form-row">
                    <div className="col col-lg-4">
                        <div className="form-group">
                            <label htmlFor="debtName">ชื่อรุ่นของหุ้นกู้</label>
                            <input required name="debtName" type="text" className="form-control" id="debtName"
                                   aria-describedby="debtName"
                                   value={repaymentData.debtName}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                }
                {(repaymentData.selectedDebtType === "หุ้นกู้" || (repaymentData.selectedDebtor !== "TG" && !repaymentData.selectedDebtType)) &&
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label
                                htmlFor="debtInterestRate">อัตราดอกเบี้ยหุ้นกู้</label>
                            <div className="input-group mb-3">
                                <input type="number"
                                       step="any"
                                       required
                                       id="debtInterestRate" name="debtInterestRate"
                                       className="form-control"
                                       aria-label="debtInterestRate"
                                       aria-describedby="debtInterestRate"
                                       value={repaymentData.debtInterestRate}
                                       onChange={handleInputChange}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {(repaymentData.selectedDebtType === "หุ้นกู้" || (repaymentData.selectedDebtor !== "TG" && !repaymentData.selectedDebtType)) &&
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="debtDefaultInterestRate">อัตราดอกเบี้ยผิดนัด</label>
                            <div className="input-group mb-3">
                                <input type="number"
                                       step="any"
                                       required
                                       id="debtDefaultInterestRate"
                                       name="debtDefaultInterestRate"
                                       className="form-control"
                                       aria-label="debtDefaultInterestRate"
                                       aria-describedby="debtDefaultInterestRate"
                                       value={repaymentData.debtDefaultInterestRate}
                                       onChange={handleInputChange}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {(repaymentData.selectedDebtType === "หุ้นกู้" || (repaymentData.selectedDebtor !== "TG" && !repaymentData.selectedDebtType)) &&
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="debtRedeemDate">กำหนดไถ่ถอน</label>
                            <input id="debtRedeemDate"
                                   type="date"
                                   required
                                   name="debtRedeemDate"
                                   className="form-control"
                                   aria-describedby="debtRedeemDate"
                                   value={repaymentData.debtRedeemDate}
                                   onChange={handleInputChange}/>
                        </div>
                    </div>
                </div>
                }
                {(repaymentData.selectedDebtType === "หุ้นกู้" || (repaymentData.selectedDebtor !== "TG" && !repaymentData.selectedDebtType)) &&
                <div className="form-row">
                    <div className="col col-lg-2">
                        <div className="form-group">
                            <label htmlFor="debtDefaultDate">วันที่ชำระดอกเบี้ยล่าสุด</label>
                            <input id="debtDefaultDate"
                                   type="date"
                                   name="debtDefaultDate"
                                   className="form-control"
                                   aria-describedby="debtDefaultDate"
                                   value={repaymentData.debtDefaultDate}
                                   onChange={handleInputChange}/>
                        </div>
                    </div>
                </div>
                }
                {(repaymentData.selectedDebtType === "หุ้นกู้" || (repaymentData.selectedDebtor !== "TG" && !repaymentData.selectedDebtType)) &&
                <div className="form-row">
                    <div className="col col-lg-4">
                        <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       id="hasDebtGuarantee"
                                       name="hasDebtGuarantee"
                                       checked={repaymentData.hasDebtGuarantee}
                                       onChange={handleInputChange}/>
                                <label className="form-check-label"
                                       htmlFor="hasDebtGuarantee">
                                    มีหลักประกันของหนี้
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {(repaymentData.selectedDebtType === "หุ้นกู้" || (repaymentData.selectedDebtor !== "TG" && !repaymentData.selectedDebtType)) && (repaymentData.hasDebtGuarantee === true) &&
                <div className="form-row">
                    <div className="col col-lg-6">
                        <div className="form-group">
                            <label htmlFor="debtGuarantee">หลักประกันของหนี้คือ</label>
                            <input required name="debtGuarantee" type="text" className="form-control" id="debtGuarantee"
                                   aria-describedby="debtGuarantee"
                                   value={repaymentData.debtGuarantee}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                }
                <hr/>
                <h4>ข้อมูลผู้รับมอบอำนาจ <small>(ผู้ยื่นคำขอรับชำระหนี้)</small></h4>
                <br/>
                <div className="form-row">
                    <div className="col col-lg-4">
                        <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       id="useLexmenAttorney"
                                       name="useLexmenAttorney"
                                       checked={repaymentData.useLexmenAttorney}
                                       onChange={handleInputChange}/>
                                <label className="form-check-label"
                                       htmlFor="useLexmenAttorney">
                                    ใช้บริการ LEXMEN ในการยื่นแบบฟอร์ม
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {repaymentData.useLexmenAttorney &&
                <div className="form-row">
                    <div className="col col-lg-3">
                        <div className="form-group">
                            <label htmlFor="contactNumber">เบอร์ติดต่อกลับ (เบอร์ของคุณ) </label>
                            <input required name="contactNumber" type="tel" className="form-control"
                                   id="contactNumber"
                                   aria-describedby="contactNumber"
                                   value={repaymentData.contactNumber}
                                   onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                }
                {repaymentData.useLexmenAttorney !== true &&
                <>
                    <div className="form-row">
                        <div className="col col-lg-2">
                            <div className="form-group">
                                <label htmlFor="attorneyPrefix">คำนำหน้า</label>
                                <select className="form-control" id="attorneyPrefix" name="attorneyPrefix"
                                        value={repaymentData.attorneyPrefix}
                                        onChange={handleInputChange}>
                                    <option>นาย</option>
                                    <option>นาง</option>
                                    <option>นางสาว</option>
                                </select>
                            </div>
                        </div>
                        <div className="col col-lg-2">
                            <div className="form-group">
                                <label htmlFor="attorneyFirstName">ชื่อ</label>
                                <input required name="attorneyFirstName" type="text" className="form-control"
                                       id="attorneyFirstName"
                                       aria-describedby="attorneyFirstName"
                                       value={repaymentData.attorneyFirstName}
                                       onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col col-lg-2">
                            <div className="form-group">
                                <label htmlFor="attorneyLastName">นามสกุล</label>
                                <input required name="attorneyLastName" type="text" className="form-control"
                                       id="attorneyLastName"
                                       aria-describedby="attorneyLastName"
                                       value={repaymentData.attorneyLastName}
                                       onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col col-lg-2">
                            <div className="form-group">
                                <label htmlFor="attorneyDOB">วันเกิด</label>
                                <input id="attorneyDOB"
                                       required
                                       type="date"
                                       name="attorneyDOB"
                                       className="form-control"
                                       aria-describedby="attorneyDOB"
                                       value={repaymentData.attorneyDOB}
                                       onChange={handleInputChange}/>
                            </div>
                        </div>
                        <div className="col col-lg-4">
                            <div className="form-group">
                                <label htmlFor="attorneyJob">อาชีพ</label>
                                <input required name="attorneyJob" type="text" className="form-control"
                                       id="attorneyJob"
                                       aria-describedby="attorneyJob"
                                       value={repaymentData.attorneyJob}
                                       onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col col-lg-6">
                            <div className="form-group">
                                <label htmlFor="attorneyAddress">ที่อยู่</label>
                                <textarea required name="attorneyAddress" className="form-control" id="attorneyAddress"
                                          rows="3"
                                          value={repaymentData.attorneyAddress}
                                          onChange={handleInputChange}
                                          placeholder="เช่น 11/12 ซอยสง่ามวงศ์ แขวงทุ่งมหาเมฆ เขตสาทร กรุงเทพมหานคร 10120"/>
                            </div>
                        </div>
                    </div>
                </>
                }
                <hr/>
                {!inapp.isInApp
                    ? <Button type="submit" variant="primary"
                              disabled={downloading}>
                        {downloading
                            ? "กำลังสร้างไฟล์.."
                            : "🔽  ดาวน์โหลดไฟล์ฟอร์มขอรับชำระหนี้ (docx)"
                        }
                    </Button>
                    : <div className="input-group">
                        <input type="email" className="form-control" placeholder="อีเมลที่ใช้รับไฟล์"
                               aria-label="Email" aria-describedby="basic-addon2"
                               value={email}
                               onChange={event => setEmail(event.target.value)}
                               required/>
                        <div className="input-group-append">
                            <button className="btn btn-success"
                                    type="submit"
                                    disabled={emailing}>
                                {emailing
                                    ? "กำลังส่งฟอร์มขอรับชำระหนี้ เข้าอีเมล.."
                                    : "✉️ ส่งฟอร์มขอรับชำระหนี้ (docx) เข้าอีเมล"
                                }
                            </button>
                        </div>
                    </div>
                }
                {/*&nbsp;&nbsp;*/}
                {/*<Button type="button" className="btn btn-info" disabled={true}>*/}
                {/*    💾 เซฟข้อมูล*/}
                {/*</Button>*/}
                {/*&nbsp;&nbsp;*/}
                {/*<Button type="button" className="btn btn-success" disabled={true}>*/}
                {/*    📬 ส่งข้อมูลให้ LEXMEN ช่วยยื่นขอรับชำระหนี้*/}
                {/*</Button>*/}
                <br/>
                <br/>
            </form>
        </div>
    );
};

export default RepaymentForm;
